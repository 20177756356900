.meta {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
    margin-top: spacing(3);
    li {
        font-family: $font-family-header;
        margin-right: spacing(3);
        color: $text-light;
        font-weight: 700;
        letter-spacing: 0.29px;
    }
}
