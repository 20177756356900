$br: 4px;

.calculator__container {
  padding: spacing(4) spacing(2);
  display: grid;
  grid-template-columns: 75% 25%;

  .result {
    margin-left: 20px;
    border-left: 2px solid #e1e4eb;
  }

  .calculator__total {
    margin-top: 15px;
    padding-top: 10px;
    display: grid;
    justify-content: left;
    border-top: 2px solid #e1e4eb;
  }
}

.calculator__results__container {
  width: 296px;
  min-width: 296px;
  border-radius: 4px;
  margin-left: 16px;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  border: 1px solid #e1e4eb;
  background: $ui-light;

  .title {
    border-radius: $br;
    color: #fff;
    display: flex;
    align-content: center;
    padding: 22px;
    background: #c2456c;
    h4 {
      color: #fff;
    }
    background: #000;
  }
}

// Calculator rows
.calculator__row {
  align-items: center;
  border-radius: $br 0 0 $br;
  display: grid;
  grid-template-columns: minmax(260px, 1fr) 1fr 1fr 1fr;
  grid-gap: spacing(2);
  max-width: $centcol;
  margin-left: auto;
  margin-right: auto;
  &.bg {
    background: $ui-light;
  }
  > * {
    padding-top: spacing(1.5);
    padding-bottom: spacing(1.5);
    height: 100%;
    align-items: center;
  }
  h4 {
    margin: 0;
    word-break: break-word;
  }
  + .calculator__row {
    margin-top: spacing(2);
  }
  @media (max-width: $bp-sm-width) {
    grid-template-columns: 1fr;
  }
}

// Calculator rows
.calculator__row__total {
  align-items: center;
  border-radius: $br 0 0 $br;
  display: grid;
  grid-template-columns: minmax(268px, 1fr) 1fr;
  grid-gap: spacing(2);
  max-width: $centcol;
  margin-left: auto;
  margin-right: auto;
  &.bg {
    background: $ui-light;
  }
  > * {
    padding-top: spacing(1.5);
    padding-bottom: spacing(1.5);
    height: 100%;
    align-items: center;
  }
  h4 {
    margin: 0;
    word-break: break-word;
  }
  + .calculator__row__total {
    margin-top: spacing(2);
  }
  @media (max-width: $bp-sm-width) {
    grid-template-columns: 1fr;
  }
}

.calculator__field__name {
  border-radius: $br;
  color: #fff;
  display: flex;
  align-content: center;
  padding: spacing(1) spacing(2);
  background: #c2456c;
  h4 {
    color: #fff;
  }
}

.calculator__total__name {
  border-radius: $br;
  color: #fff;
  display: flex;
  align-content: center;
  padding: spacing(1) spacing(2);
  background: #6d768f;
  h4 {
    color: #fff;
  }
}

.prefix__number {
  color: #c2456c;
  width: spacing(4);
  height: spacing(4);
  margin-right: 16px;
  align-items: center;
  display: flex;
  justify-content: center;
  background: #fff;
  border-radius: 50%;
  font-weight: 600;
}

.calculator__field {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  button {
    height: 100%;
  }
  .form-item {
    margin: 0;
    width: 100%;
  }
  input {
    text-align: center;
  }
  input:disabled {
    background: transparent;
    border: none;
  }
  &.calculator__field--restricted {
    input {
      max-width: 120px;
    }
  }
  &.calculator__field--centered {
    input {
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.calculator__results__field {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  button {
    height: 100%;
  }
  .form-item {
    margin: 0;
    width: 100%;
  }
  input {
    text-align: center;
    margin: 20px auto;
    width: 200px;
  }
  input:disabled {
    background: transparent;
    border: none;
  }
  &.calculator__field--restricted {
    input {
      max-width: 120px;
    }
  }
  &.calculator__field--centered {
    input {
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.calculator__field__header {
  h5 {
    text-align: center;
  }
}
