.success__icon {
    position: relative;
    margin-bottom: spacing(4);
    width: 100%;
    max-width: 240px;
    margin-left: auto;
    margin-right: auto;
    overflow: visible !important;
}

.star {
    animation: explosion 1s;
    transform-origin: center;
    animation-fill-mode: forwards;
    animation-delay: 0.5s;
    opacity: 0;
}

.checkcircle {
    animation: zoomIn 1s;
    transform-origin: center;
    animation-fill-mode: forwards;
    opacity: 0;
    z-index: 1;
}

@keyframes explosion {
    0% {
        transform: scale(0) rotate(40deg);
    }
    60% {
        transform: scale(1.2) rotate(10deg);
    }
    100% {
        opacity: 1;
        transform: scale(1) rotate(0deg);
    }
}
@keyframes zoomIn {
    0% {
        transform: scale(0);
    }
    80% {
        transform: scale(1.2);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
