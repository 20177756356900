@font-face {
  font-family: 'HK Grotesk';
  src: url('../fonts/HKGrotesk-Regular.woff2') format('woff2'),
    url('../fonts/HKGrotesk-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url('../fonts/HKGrotesk-Bold.woff2') format('woff2'),
    url('../fonts/HKGrotesk-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url('../fonts/HKGrotesk-SemiBold.woff2') format('woff2'),
    url('../fonts/HKGrotesk-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

// Not imported yet
// @font-face {
//     font-family: 'HK Grotesk';
//     src:
//         url('../fonts/HKGrotesk-Italic.woff2') format('woff2'),
//         url('../fonts/HKGrotesk-Italic.woff') format('woff');
//     font-weight: normal;
//     font-style: italic;
//     font-stretch: normal;
// }

@font-face {
  font-family: 'HK Grotesk';
  src: url('../fonts/HKGrotesk-Regular.woff2') format('woff2'),
    url('../fonts/HKGrotesk-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url('../fonts/HKGrotesk-SemiBold.woff2') format('woff2'),
    url('../fonts/HKGrotesk-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

.Heading-1-Left-White {
  font-size: 64px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: -0.64px;
  color: var(--white);
}

.Heading-1-Left-Basalt {
  font-size: 64px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: -0.64px;
  color: var(--basalt);
}

.Heading-2-Left-White {
  font-size: 56px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: -0.56px;
  color: var(--white);
}

.Heading-2-Left-Basalt {
  font-size: 56px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: -0.56px;
  color: var(--basalt);
}

.Heading-3-Left-White {
  font-size: 48px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: -0.48px;
  color: var(--white);
}

.Heading-3-Left-Basalt {
  font-size: 48px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: -0.48px;
  color: var(--basalt);
}

.Heading-4-Left-White {
  font-size: 40px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.4px;
  color: var(--white);
}

.Heading-4-Left-Basalt {
  font-size: 40px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.4px;
  color: var(--basalt);
}

.Heading-5-Left-White {
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.32px;
  color: var(--white);
}

.Heading-5-Left-Basalt {
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.32px;
  color: var(--basalt);
}

.Heading-6-Left-White {
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.24px;
  color: var(--white);
}

.Heading-6-Left-Basalt {
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.24px;
  color: var(--basalt);
}

.Tags-BadgesBadgesYellow {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.2px;
  text-align: center;
  color: var(--yellow);
}

.Tags-BadgesBadgesViolet {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.2px;
  text-align: center;
  color: var(--violet);
}

.Tags-BadgesBadgesUltramarine {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.2px;
  text-align: center;
  color: var(--ultramarine);
}

.Tags-BadgesBadgesTurquoise {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.2px;
  text-align: center;
  color: var(--turquoise);
}

.Tags-BadgesBadgesPurple {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.2px;
  text-align: center;
  color: var(--purple);
}

.Tags-BadgesBadgesOrange {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.2px;
  text-align: center;
  color: var(--orange);
}

.Tags-BadgesBadgesGold {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.2px;
  text-align: center;
  color: var(--gold);
}

.Tags-BadgesBadgesCoral {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.2px;
  text-align: center;
  color: var(--coral);
}

.Tags-BadgesBadgesBlue {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.2px;
  text-align: center;
  color: var(--blue);
}

.Tags-BadgesBadgesAzure {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.2px;
  text-align: center;
  color: var(--azure);
}

.Heading-7-Left-White {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.2px;
  color: var(--white);
}

.Heading-7-Left-Basalt {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.2px;
  color: var(--basalt);
}

.Paragraph-1Left-ItalicWhite {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.6;
  letter-spacing: -0.2px;
  color: var(--white);
}

.Paragraph-1Left-ItalicSlate {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.6;
  letter-spacing: -0.2px;
  color: var(--slate);
}

.Paragraph-1Left-ItalicBasalt {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.6;
  letter-spacing: -0.2px;
  color: var(--basalt);
}

.Paragraph-1LeftWhite {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.2px;
  color: var(--white);
}

.Paragraph-1LeftSlate {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.2px;
  color: var(--slate);
}

.Paragraph-1LeftBasalt {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.2px;
  color: var(--basalt);
}

.Heading-8CentreBasalt {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.16px;
  text-align: center;
  color: var(--basalt);
}

.Heading-8-Left-White {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.16px;
  color: var(--white);
}

.Heading-8-Left-Basalt {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.16px;
  color: var(--basalt);
}

.ButtonLeftSlate {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.16px;
  color: var(--slate);
}

.ButtonCentreSlate {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.16px;
  text-align: center;
  color: var(--slate);
}

.ButtonCentreAqua {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.16px;
  text-align: center;
  color: var(--aqua);
}

.Button-Left-White {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.16px;
  color: var(--white);
}

.Button-Left-Blue {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.16px;
  color: var(--blue);
}

.Button-Left-Basalt-2 {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.16px;
  color: var(--basalt);
}

.Button-Left-Basalt {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.16px;
  color: var(--basalt);
}

.Button-Left-Aqua {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.16px;
  color: var(--aqua);
}

.Button-Centre-White {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.16px;
  text-align: center;
  color: var(--white);
}

.Button-Centre-Blue {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.16px;
  text-align: center;
  color: var(--blue);
}

.Button-Centre-Basalt {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.16px;
  text-align: center;
  color: var(--basalt);
}

.Paragraph-2Left-ItalicWhite {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.5;
  letter-spacing: -0.16px;
  color: var(--white);
}

.Paragraph-2Left-ItalicSlate {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.5;
  letter-spacing: -0.16px;
  color: var(--slate);
}

.Paragraph-2Left-ItalicBasalt {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.5;
  letter-spacing: -0.16px;
  color: var(--basalt);
}

.Paragraph-2LeftWhite {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.16px;
  color: var(--white);
}

.Paragraph-2LeftSlate-2 {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.16px;
  color: var(--slate);
}

.Paragraph-2LeftSlate {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.16px;
  color: var(--slate);
}

.Paragraph-2LeftBasalt-2 {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.16px;
  color: var(--basalt);
}

.Paragraph-2LeftBasalt {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.16px;
  color: var(--basalt);
}

.Tags-BadgesTagsYellow {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: -0.14px;
  text-align: center;
  color: var(--yellow);
}

.Tags-BadgesTagsViolet {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: -0.14px;
  text-align: center;
  color: var(--violet);
}

.Tags-BadgesTagsUltramarine {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: -0.14px;
  text-align: center;
  color: var(--ultramarine);
}

.Tags-BadgesTagsTurquoise {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: -0.14px;
  text-align: center;
  color: var(--turquoise);
}

.Tags-BadgesTagsPurple {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: -0.14px;
  text-align: center;
  color: var(--purple);
}

.Tags-BadgesTagsOrange {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: -0.14px;
  text-align: center;
  color: var(--orange);
}

.Tags-BadgesTagsGold {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: -0.14px;
  text-align: center;
  color: var(--gold);
}

.Tags-BadgesTagsCoral {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: -0.14px;
  text-align: center;
  color: var(--coral);
}

.Tags-BadgesTagsBlue {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: -0.14px;
  text-align: center;
  color: var(--blue);
}

.Tags-BadgesTagsAzure {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: -0.14px;
  text-align: center;
  color: var(--azure);
}

.Heading-9LeftWhite {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.14px;
  color: var(--white);
}

.Heading-9LeftSlate {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.14px;
  color: var(--slate);
}

.Heading-9LeftBasalt {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.14px;
  color: var(--basalt);
}

.Paragraph-3Left-ItalicWhite {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.71;
  letter-spacing: -0.14px;
  color: var(--white);
}

.Paragraph-3Left-ItalicSlate {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.71;
  letter-spacing: -0.14px;
  color: var(--slate);
}

.Paragraph-3Left-ItalicBasalt {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.71;
  letter-spacing: -0.14px;
  color: var(--basalt);
}

.Paragraph-3LeftWhite {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: -0.14px;
  color: var(--white);
}

.Paragraph-3LeftSuccess {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: -0.14px;
  color: var(--turquoise);
}

.Paragraph-3LeftSlate {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: -0.14px;
  color: var(--slate);
}

.Paragraph-3LeftError {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: -0.14px;
  color: var(--coral);
}

.Paragraph-3LeftBasalt {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: -0.14px;
  color: var(--basalt);
}
