.hero {
    border: 1px solid #e5e5ea;
    border-radius: 16px;
    background-color: $ui-light;
    padding: spacing(5);
    margin-left: spacing(2);
    margin-right: spacing(2);
    p {
        font-family: $font-family-header;
        margin-top: spacing(2);
        color: $ui-dark;
    }
}
