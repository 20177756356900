.feature__3col__list {
    &.bg {
        background: #f7f8f9;
    }
}
.feature__3col__list__text {
    margin-bottom: spacing(4);
    h2 {
        width: 100%;
        font-size: 2.5rem;
        font-weight: 600;
    }
    p {
        width: 100%;
        color: $text-light;
        font-weight: 500;
        font-size: 1.2rem;
    }
}
.feature__3col__list__item {
    padding: spacing(4);
    background: #fff;
    grid-column: span 12;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: spacing(4);
    grid-row-gap: spacing(4);
    align-items: flex-start;
    @media (max-width: $bp-md-width) {
        grid-template-columns: 1fr;
        img {
            margin: spacing(2) auto;
        }
    }
    &:nth-child(1) {
        background-color: rgba(0, 203, 204, 0.1);
    }
    &:nth-child(2) {
        background-color: rgba(255, 169, 136, 0.1);
    }
    &:nth-child(3) {
        background-color: rgba(253, 68, 146, 0.1);
    }
    &:nth-child(4) {
        background-color: rgba(0, 89, 195, 0.1);
    }
    @media (max-width: $bp-md-width) {
        grid-column: span 3;
    }
    h3 {
        font-weight: 600;
        margin-bottom: spacing(4);
    }
    p {
        color: $text-light;
        font-weight: 500;
        font-size: 1.2rem;
    }
    p.big {
        font-size: 1.2em;
    }
}
.feature__3col__list__item--full {
    @media (max-width: $bp-md-width) {
        grid-column: span 6;
    }
}

.feature__3col__list__sub__grid {
    display: grid;
}
.feature__3col__list__inner {
}
