.team__time__header {
    display: flex;
    padding: spacing(2);
    align-items: center;
    position: relative;
    background: #d1d1d6;
    h2 {
        font-size: 1.25rem;
        color: #000;
        margin: 0;
        &.editable {
            background: #ffffff2c;
            padding: 8px;
            border-radius: 4px;
        }
        + h2 {
            font-weight: 300;
            margin-left: 16px;
        }
    }
    button {
        margin: 0 0 0 auto;
        svg path {
            stroke: #fff;
        }
    }
}

// Team time subheader
.team__time__subheader {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: spacing(2);
}
.team__time__subheader__title {
    grid-column: span 2;
    font-size: 0.875rem;
    color: $ui-dark;
    &:nth-child(2) {
        text-align: right;
    }
}
.team__time__period {
    font-weight: 600;
    grid-column: span 3;
}

// Team time table
.team__time__content {
    padding: spacing(2);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: spacing(2);
    max-height: calc(100vh - 200px);
    overflow: scroll;
    table {
        th {
            background-color: #efeff4;
            font-size: 0.75rem;
            padding: spacing(0.5);
            line-height: 2;
            font-weight: 500;
        }
        td {
            padding: spacing(1) spacing(0.5);
            vertical-align: middle;
            border-bottom: none;
        }
    }
}

.team__time__column {
    background: $ui-light;
    padding: spacing(2);
}

.team__time__row__label {
    font-weight: 600;
    font-size: 0.875rem;
    margin-bottom: spacing(1);
}

.team__time__timeSpent {
    display: none;
}

.team__time__input {
    height: 32px;
    text-align: center;
}

.team__time__row {
    td:first-child {
        padding: spacing(1.5) spacing(1);
    }
    span {
        font-size: 0.875rem;
        display: block;
        width: 50px;
        text-align: center;
        border-radius: 4px;
        background-color: #ceddfc33;
    }
}

.team__time__period .form-item {
    margin-bottom: 0px;
    select {
        height: 32px;
    }
}

.team__time__page {
    margin: 0 spacing(4);
}

.team__time__info {
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        grid-area: span 1 / span 7;
        li {
            font-size: 1.25rem;
            padding: spacing(3) spacing(4);
            position: relative;
            cursor: pointer;
            &.active:after {
                content: '';
                position: absolute;
                left: 0px;
                bottom: 0px;
                height: 4px;
                width: 100%;
                background-color: #e5e5ea;
            }
        }
    }
    .company__info {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: space-between;
        grid-area: span 1 / span 5;
    }
}

.team__time__team {
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin: spacing(2) 0;
    grid-area: span 1 / span 5;
    li {
        border-radius: 4px 4px 0 0;
        background-color: #ffffff;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
        padding: spacing(0.5);
        margin-bottom: spacing(2);
        span {
            font-size: 1.5rem;
            padding: spacing(3);
            display: block;
        }
        .percentage__subheader {
            margin: 0;
        }
    }
}

.team__time__overview {
    grid-column-start: 8;
    grid-column-end: 13;
    background-color: $ui-light;
    padding: spacing(3);
    margin-top: spacing(2);
}

td.figure,
th.figure {
    width: 58px;
    max-width: 58px;
    text-align: center;
}

.team__time__overview {
    .slider-handle {
        display: none;
    }
}
