.breadcrumb__intro {
    width: 100%;
    max-width: 200px;
    height: 4px;
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 20px;
    margin: 0 auto;
    @for $i from 1 to 3 {
        &.breadcrumb__intro--step-#{$i} {
            &:after {
                transition: transform $easing;
                transform-origin: left;
                transform: scale(#{(($i - 1) * 0.5)}, 1);
            }
        }
    }
    &:before,
    &:after {
        width: 100%;
        height: 2px;
        content: '';
        left: 0px;
        top: 0px;
        bottom: 0px;
        margin: auto;
        z-index: -1;
        position: absolute;
        background: #e5e5ea;
    }
    &:after {
        background: blue;
    }
    li {
        list-style: none;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: relative;
        background: #fff;
        border: 2px solid #e5e5ea;
        transition: $easing;
        &.active {
            border: 2px solid blue;
        }
        &.done {
            border: 2px solid blue;
            background: blue;
            &:after {
                content: '';
                transform: translate(0, -1px) rotate(45deg);
                height: 8px;
                width: 5px;
                border-bottom: 2px solid #fff;
                border-right: 2px solid #fff;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
            }
        }
    }
}
