$accent: #7ceff3;
$border: #e5e5ea;

$primary: #514ffe;
$ui-dark: #252834;
$ui-light: #f5f5fa;

$text-light: #8e8e93;
$border: #e5e5ea;
$success: #4cd964;

$stone: #e1e4eb;
$marble: #f7f9fc;
$aqua: #7ceff3;
$basalt: #051133;
$turquoise: #00c5cc;

:root {
  --aqua: #7ceff3;
  --basalt: #051133;
  --turquoise: #00c5cc;
  --azure: #33a0ff;
  --blue: #334eff;
  --ultramarine: #5a00e0;
  --purple: #8700cd;
  --violet: #ad00b8;
  --coral: #ff4d70;
  --orange: #ff7c4d;
  --gold: #ffa64d;
  --yellow: #fec34c;
  --space: #16203d;
  --diabase: #2a3552;
  --flint: #454e66;
  --slate: #6d768f;
  --steel: #9aa2b8;
  --granite: #ced3e0;
  --stone: #e1e4eb;
  --quartz: #f1f2f6;
  --marble: #f7f9fc;
  --white: #ffffff;
  --aqua-disabled: rgba(124, 239, 243, 0.5);
  --aqua-hover: #6fd6da;
  --aqua-pressed: #63c0c3;
  --quartz-pressed: #c1c2c5;
  --quartz-hover: #d8d9dc;
  --quartz-disabled: rgba(241, 242, 246, 0.5);
  --fill-zero-25: rgba(0, 0, 0, 0.25);
}
