.add__step__control {
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    ul {
        margin: 0;
        list-style-type: none;
        li {
            border-radius: 4px;
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
            background-color: #fff;
            padding: spacing(2);
            margin-bottom: spacing(1);
            font-weight: 600;
            position: relative;
            overflow: hidden;
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
                position: absolute;
                left: 0px;
                top: 0px;
                height: 4px;
                width: 100%;
            }
        }
    }
}
