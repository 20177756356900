.g {
    display: grid;
}

.g--fixed {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: spacing(3.75);
}

.g--fixed--stack {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: spacing(3.75);
    @media (max-width: $bp-md-width) {
        grid-template-columns: repeat(6, 1fr);
    }
    @media (max-width: $bp-sm-width) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: $bp-xs-width) {
        grid-template-columns: repeat(3, 1fr);
    }
}

.g--fluid {
    width: 100%;
    padding: 0 spacing(4);
    grid-template-columns: repeat(12, 1fr);
    grid-gap: spacing(3);
}

.g--fluid--stack {
    width: 100%;
    padding: 0 spacing(4);
    grid-template-columns: repeat(12, 1fr);
    grid-gap: spacing(3);
    @media (max-width: $bp-lg-width) {
        grid-template-columns: repeat(6, 1fr);
    }
    @media (max-width: $bp-md-width) {
        grid-template-columns: repeat(6, 1fr);
    }
    @media (max-width: $bp-sm-width) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: $bp-xs-width) {
        grid-template-columns: repeat(3, 1fr);
    }
}

$columns: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12;

@each $column in $columns {
    .s-#{$column} {
        grid-column-start: $column;
    }
    .e-#{$column} {
        grid-column-end: $column + 1;
    }
    .span-#{$column} {
        grid-column: span $column;
    }
}
