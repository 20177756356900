.revenue__header__row {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    margin: spacing(2) spacing(4);
    align-items: center;
    text-align: center;
    > * + * {
        border-left: 1px solid #e5e5ea;
    }
}
.revenue__row {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    margin: spacing(2) spacing(4);
    padding: spacing(2) spacing(4);
    align-items: center;
    text-align: center;
    grid-column-gap: spacing(8);
    min-height: 48px;
    &:last-child {
        padding-bottom: spacing(4);
    }
    > * {
        margin: 0;
    }
}

.revenue__header__column {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    text-align: center;
    padding: spacing(4);
    > * {
        width: 100%;
    }
}

.revenue__goal__input {
    text-align: center;
}

.revenue__goals {
    background: $ui-light;
    margin: spacing(4) spacing(4);
}
