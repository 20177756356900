// Percentage subheader
.percentage__subheader {
    margin-top: spacing(2);
    margin-bottom: spacing(2);
    display: flex;
    flex-wrap: wrap;
}
.percentage__total {
    width: 50%;
}
.percentage__amount {
    text-align: right;
    width: 50%;
    font-weight: 600;
    font-size: 1.2;
    &.error {
        color: red;
    }
}

.percentage__graph {
    width: 100%;
    height: 60px;
    margin-top: spacing(1);
    display: flex;
    position: relative;
}

@for $i from 0 to 100 {
    .percentage__bar__#{$i} {
        width: #{$i + '%'};
        height: 100%;
    }
}
