.overlap__image__grid {
    width: 100%;
    height: max-content;
    display: grid;
    grid-template-rows: 1fr 6fr 6fr 6fr 1fr;
    grid-template-columns: 1fr 6fr 6fr 6fr 1fr;
    img {
        grid-row: 2/5;
        grid-column: 2/5;
        box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.1);
    }
    &.style--1 {
        .dots {
            grid-row: 1/4;
            grid-column: 1/4;
            background-image: radial-gradient($primary 30%, transparent 30%),
                radial-gradient($primary 30%, transparent 30%);
            background-position: 0 0, 50px 50px;
            background-size: 10px 10px;
        }
        .solid {
            grid-row: 2/6;
            grid-column: 1/4;
            background: #7ceff3;
            z-index: -1;
        }
    }
    &.style--2 {
        .dots {
            grid-row: 1/4;
            grid-column: 3/6;
            background-image: radial-gradient(#7ceff3 30%, transparent 30%),
                radial-gradient(#7ceff3 30%, transparent 30%);
            background-position: 0 0, 50px 50px;
            background-size: 10px 10px;
        }
        .solid {
            grid-row: 3/6;
            grid-column: 1/4;
            background: #89dbff;
            z-index: -1;
        }
    }
}
