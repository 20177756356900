.collateral__control {
    &__header {
        display: flex;
        justify-content: space-between;
    }
    &__list {
        min-height: 280px;
        position: relative;
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
        }
        ul li {
            border-radius: 4px;
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
            background-color: #fff;
            padding: spacing(2);
            margin-bottom: spacing(1);
            font-weight: 600;
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    &__popover {
        position: relative;
        display: none;
        width: 100%;
        &__scroll {
            max-height: 240px;
            overflow: scroll;
        }
        &--active {
            display: block;
        }
    }
}

.collateral__control__header {
    margin-bottom: 16px;
}
