.grid__figure {
}

.grid__figure__inner {
    width: 100%;
    max-width: $centcol;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: auto;
    margin: 0 auto;
    @media (max-width: $bp-md-width) {
        grid-template-columns: 1fr;
        > * + * {
            margin-top: spacing(2);
        }
    }
}
.grid__figure__item {
    text-align: center;
    h2 {
        color: $accent;
        font-size: 8rem;
    }
    p {
        font-size: 1.2rem;
    }
    &.small {
        h2 {
            font-size: 3rem;
        }
    }
}

.grid__figure__title {
    margin-bottom: spacing(4);
}
