.multi__option__popover {
    display: none;
    overflow: auto;
    left: 0;
    top: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    background: #fff;
    padding: spacing(2);
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px 4px 0 0;
    z-index: 20;
    &--active {
        display: block;
    }
}

.multi__option__control__list {
    min-height: 10vh;
}

.multi__option__control {
}

.sales__cycle__custom__question__title p {
    font-weight: bold;
    margin-top: 1px;
}

.sales__cycle__custom__question__content textarea {
    margin: '10px 0px';
}
.sales__cycle__custom__question__content button {
    padding: 10px;
    background: none;
    margin: 10px 0;
    font-size: 15px;
    &:hover,
    &:focus {
        cursor: pointer;
    }
}
