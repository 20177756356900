.website__nav {
    width: 100%;
    padding: 0 spacing(4);
    height: 80px;
}
.website__nav__inner {
    max-width: 1140px;
    margin: 0 auto;
    height: 88px;
    padding: spacing(1) 0;
    display: flex;
    align-items: center;
    .button {
        background: #55eae4;
        color: #000;
        transition: 0.2s;
        font-weight: 600;
        &:hover {
            background: darken(#55eae4, 10%);
        }
    }
}

.website__nav__items {
    margin-left: auto;
    margin-right: spacing(2);
    @media (max-width: $bp-md-width) {
        position: absolute;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: $ui-light;
        opacity: 0;
        pointer-events: none;
        transition: 0.4s;
        * + * {
            margin-top: spacing(4);
        }
        &.active {
            opacity: 1;
            pointer-events: all;
        }
    }
}

.website__nav a svg,
.website__footer svg {
    max-width: 160px;
    height: 28px;
}

.website__nav__item {
    font-weight: 600;
    color: #000;
    text-decoration: none;
    transition: 0.2s;
    &:hover {
        color: $text-light;
    }
    + * {
        margin-left: spacing(2);
    }
}
.website__nav__hamburger {
    height: 24px;
    width: 32px;
    position: relative;
    margin-left: auto;
    @media (min-width: $bp-md-width) {
        display: none;
    }
    li {
        width: 32px;
        height: 3px;
        background: #000;
        list-style-type: none;
        position: absolute;
    }
    .top {
        top: 0;
        left: 0;
    }
    .middle {
        top: 0;
        bottom: 0;
        margin: auto;
    }
    .bottom {
        bottom: 0;
        left: 0;
    }
}
