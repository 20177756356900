.payment__container {
    max-width: $centcol;
    margin: 0 auto;
    text-align: center;
    margin-top: spacing(5);
    > * + * {
        width: 100%;
        margin-top: 40px;
    }
}

.payment__form__container {
    width: 100%;
    max-width: 400px;
    padding: 0 spacing(2);
    margin-left: auto;
    margin-right: auto;
}

.payment__form__item {
    margin-top: spacing(4);
    label {
        font-weight: 600;
        margin-bottom: spacing(1);
        text-align: left;
    }
    .button {
        width: 100%;
    }
}

.payment__links {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 400px;
}

.method__select__button {
    margin-top: spacing(12);
    max-width: 240px;
    width: 100%;
}

.payment__form__dual__item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: spacing(2);
    width: 100%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-top: spacing(4);
    label {
        font-weight: 600;
        margin-bottom: spacing(1);
        text-align: left;
    }
}

.payment__summary--split {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: $centcol;
    margin: spacing(4) auto 0 auto;
}

.summary__container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &:after {
        content: '';
        position: absolute;
        right: 0px;
        top: 0px;
        height: 100%;
        width: 1px;
        background-color: #979797;
    }
}

.payment__container__form {
    width: 360px;
}

.StripeElement {
    box-sizing: border-box;

    padding: 12px 12px;

    border: 1px solid transparent;
    border-radius: 4px;

    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;

    font-size: $input-default-font-size;
    font-weight: $input-default-font-weight;
    height: $input-default-height;
    background-color: $input-default-background;
    border: $input-default-border-width $input-default-border-style
        $input-default-border-color;
    border-radius: $input-default-border-radius;
    box-shadow: $input-default-box-shadow;
    color: $input-default-color-text;
}

.payment__processing {
    display: none;
    &--active {
        display: grid;
    }
    position: fixed;
    z-index: 2;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, 0.8);
    justify-content: center;
    align-items: flex-start;
    .modal {
        margin-top: 130px;
        max-width: 400px;
        min-width: 320px;
        border-radius: 28px;
        box-shadow: 0 11px 23px 0 rgba(0, 0, 0, 0.18);
        background: #ffffff;
        p {
            margin: spacing(7) spacing(2) spacing(12) spacing(2);
            text-align: center;
        }
        svg {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 216px;
            max-height: 100px;
            margin: spacing(13.75) auto 0 auto;
        }
    }
}
