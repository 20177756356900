// Business goals grid
.business__goals__grid {
    display: grid;
    width: 100%;
    grid-template-areas:
        'progress main links'
        'steps  main links';
    grid-template-columns: minmax(200px, 300px) 1fr 280px;
    grid-auto-rows: 1fr 1fr;
    padding: spacing(4);
    grid-gap: spacing(2);
    @media (max-width: 1140px) {
        grid-template-areas:
            'progress main'
            'steps    main'
            'links    main';
        grid-template-columns: minmax(200px, 300px) 1fr;
    }
}
