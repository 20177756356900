.currency__switcher {
  border: 1px solid transparent;
  background: #051133;
  color: #fff;

  .light > & {
    background-color: #fff;
    border: 1px solid rgba(17, 17, 19, 0.1);
    color: rgba(17, 17, 19, 0.8);
    -webkit-appearance: none;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="9" height="12" viewBox="0 0 9 12"><path fill="rgba(0, 0, 0, .4)" d="M0.722,4.823L-0.01,4.1,4.134-.01,4.866,0.716Zm7.555,0L9.01,4.1,4.866-.01l-0.732.726ZM0.722,7.177L-0.01,7.9,4.134,12.01l0.732-.726Zm7.555,0L9.01,7.9,4.866,12.01l-0.732-.726Z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 0.65em center;
    padding-right: 28px;

    &:hover {
      outline: none;
      color: $input-hover-color-text;
      background-color: $input-hover-background;
      border: $input-hover-border-width $input-hover-border-style $input-hover-border-color;
      box-shadow: $input-hover-box-shadow;
    }
    &:focus {
      @include transition;
      outline: none;
      color: $input-focus-color-text;
      background-color: $input-focus-background;
      border: $input-focus-border-width $input-focus-border-style $input-focus-border-color;
      box-shadow: $input-focus-box-shadow;
    }
    &:active {
      @include transition;
      outline: none;
      color: $input-focus-color-text;
      background-color: $input-focus-background;
      border: $input-focus-border-width $input-focus-border-style $input-focus-border-color;
      box-shadow: $input-focus-box-shadow;
    }
  }
  &:hover,
  &:focus,
  &:active {
    border: 1px solid transparent;
    background: #051133;
    color: #fff;
  }
}
