.chevron__link {
    padding: spacing(3) spacing(2);
    width: 100%;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    display: block;
    background: #fff;
    text-align: left;
    font-weight: 600;
    color: $ui-dark;
    text-decoration: none;
    position: relative;
    border-radius: $border;
    transition: $easing;
    &:hover {
        color: $ui-dark;
        transform: scale(1.02);
    }
    &:after {
        border-right: 2px solid $primary;
        border-top: 2px solid $primary;
        right: 16px;
        top: 0;
        bottom: 0;
        margin: auto;
        transform: rotate(45deg);
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        transition: 0.2s;
    }
}
