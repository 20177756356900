.business__goals__link__list {
    grid-area: links;
    grid-gap: spacing(1);
    a {
        display: block;
        background: $ui-light;
        padding: spacing(2);
        border: solid 1px $border;
        color: $ui-dark;
        font-weight: 600;
        position: relative;
        text-decoration: none;
        border-radius: 8px;
        padding-right: spacing(4);
        + a {
            margin-top: spacing(1);
        }
        &:after {
            border-right: 2px solid $primary;
            border-top: 2px solid $primary;
            right: 16px;
            top: 0;
            bottom: 0;
            margin: auto;
            transform: rotate(45deg);
            content: '';
            position: absolute;
            width: 8px;
            height: 8px;
            transition: 0.2s;
        }
        &:hover {
            &:after {
                transform: translate(4px, 0) rotate(45deg);
            }
        }
    }
}
