.complete__card {
    margin-left: auto;
    margin-right: auto;
    background: #fff;
    width: 400px;
    min-height: 480px;
    box-shadow: 0 11px 23px 0 rgba(0, 0, 0, 0.18);
    border-radius: spacing(4);
    display: flex;
    align-content: flex-end;
    flex-wrap: wrap;
    > * {
        width: 100%;
    }
    .button {
        margin: spacing(8) spacing(4) spacing(2) spacing(4);
    }
}
