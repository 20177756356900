.web__pricing__container {
    h1 {
        font-weight: 400;
        font-size: 3rem;
    }
}
.web__pricing__text {
    max-width: 600px;
    text-align: center;
    margin: 0 auto spacing(8);
}
.web__pricing__card__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    max-width: $centcol;
    grid-column-gap: spacing(4);
    grid-row-gap: spacing(4);
    margin: auto;
    @media (max-width: $bp-sm-width) {
        grid-template-columns: 1fr;
    }
    h2 {
    }
}
.web__pricing__card {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    padding: spacing(4);
    * {
        width: 100%;
    }
    .button {
        margin-top: spacing(4);
    }
}
.tick__list {
    padding: 0;
    padding-top: spacing(4);
    margin: 0;
    font-size: 1.2rem;
    li {
        list-style: none;
        line-height: 1.5;
        position: relative;
        padding: spacing(1) 0 spacing(1) spacing(4);
        &:before {
            content: '';
            position: absolute;
            width: 16px;
            height: 8px;
            border-bottom: 2px solid $accent;
            border-left: 2px solid $accent;
            transform: rotate(-45deg);
            left: 0px;
            top: 0px;
            bottom: 0px;
            margin: auto;
        }
    }
}
.web__pricing__card__price {
    margin-top: spacing(2);
    p {
        font-size: 2rem;
        font-weight: 600;
        margin-bottom: spacing(1);
    }
    small {
        font-size: 1em;
    }
}

.web__pricing__sub {
    margin-top: spacing(4);
}
