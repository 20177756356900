$selectable__card__content__padding: spacing(2);

.selectable__card__container {
    display: flex;
    justify-content: center;
    max-width: $centcol;
}

.selectable__card {
    max-width: 263px;
    border-radius: 8px;
    border: 2px solid $stone;
    min-height: 320px;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-self: center;
    margin-right: spacing(2);

    :last-child {
        margin-right: 0;
    }
    h2 {
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: spacing(1);
    }
    h3 {
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.5em;
        margin-bottom: spacing(2);
    }
    .price {
        font-weight: 600;
        font-size: 1.5rem;
        letter-spacing: -0.24px;
        padding: spacing(2) $selectable__card__content__padding;
        margin-left: -$selectable__card__content__padding;
        margin-right: -$selectable__card__content__padding;
        background-color: $marble;
        span:first-child {
            font-size: 2rem;
        }
    }
    p {
        font-size: 0.875rem;
        color: #051133;
        margin-top: spacing(1);
    }
    a {
        margin: auto spacing(3) spacing(5) spacing(3);
    }
    &.is-active {
        border: 2px solid $primary;
    }
    .billing__period {
        font-size: 0.875rem;
        line-height: 1.714285714em;
        margin-left: spacing(1);
    }
    .licenses {
        font-size: 0.875rem;
        line-height: 1.714285714em;
    }
    &__included {
        margin-bottom: auto;
        padding: spacing(2);
        margin-bottom: spacing(2);
    }

    &__included__list {
        font-size: 0.875rem;
        line-height: 1.714285714em;
        margin: 0;
        margin-bottom: spacing(4);
        list-style: none;
        li {
            display: flex;
            align-items: center;
            &:before {
                content: '';
                background-color: #051133;
                width: 4px;
                height: 4px;
                margin-right: spacing(1);
                margin-left: 4px;
                border-radius: 50%;
                align-self: flex-start;
                display: inline-block;
                position: relative;
                top: 0.75em;
                flex-shrink: 0;
            }
        }
    }

    .button {
        background-color: $aqua;
        color: $basalt;
        font-weight: 600;
        margin-right: spacing(3);

        &:hover,
        &:focus {
            background-color: $turquoise;
        }
    }
}

.selectable__card__content {
    padding: spacing(4) $selectable__card__content__padding spacing(2);
}

.selectable__card__features {
    min-height: 140px;
}
