.header__spacer {
    padding-top: 80px;
}
header {
    h1 {
        font-size: 2rem;
        margin: spacing(2) 0;
    }
}

.header__logo {
    height: 28px;
    z-index: 1;
}

.header__actions {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: spacing(2);
    z-index: 1;
    align-items: center;
    color: #fff;
}
