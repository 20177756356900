.business__goals__card {
    grid-area: main;
    padding: spacing(4);
    background: #fff;
    border: solid 1px #e5e5ea;
    border-radius: 8px;
    margin: 0 auto;
    width: 100%;
    max-width: 750px;
}

.business__goals__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: spacing(2);
    div {
        color: $text-light;
    }
    p {
        color: $text-light;
        width: 100%;
    }
}

.business__goals__card__nav {
    display: flex;
    justify-content: space-between;
}

.business__goals__card__form {
    .is-col {
        position: relative;
    }
    label {
        font-weight: 600;
        margin-bottom: spacing(1);
    }
}
