.form__tooltip {
    font-weight: 600;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: solid 2px $ui-dark;
    position: absolute;
    right: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: help;
    small {
        position: absolute;
        width: 200px;
        padding: spacing(2);
        transform: translate(120px, 0);
        right: -100px;
        top: -20px;
        background: #fff;
        border: solid 1px $border;
        z-index: 1;
        opacity: 0;
        user-select: none;
        pointer-events: none;
        transition: 0.2s;
    }
    &:hover {
        small {
            opacity: 1;
        }
    }
}
