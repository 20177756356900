.client__header__row {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    margin: spacing(2) spacing(4);
    align-items: center;
    text-align: center;
    border-bottom: 1px solid #e5e5ea;
    > * + * {
        border-left: 1px solid #e5e5ea;
    }
}
.client__row {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    margin: spacing(2) spacing(4);
    align-items: center;
    text-align: left;
    grid-column-gap: spacing(8);
    min-height: 48px;
    &:last-child {
        padding-bottom: spacing(4);
    }
    > * {
        margin: 0;
    }
}

.client__header__column {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    text-align: center;
    padding: spacing(4);
    > * {
        width: 100%;
    }
}

.client__goal__input {
    text-align: center;
}

.client__goals {
    background: $ui-light;
    margin: spacing(4) spacing(4);
}

.client__goal__figure {
    display: flex;
    flex-wrap: wrap;
    .slider {
        margin-top: spacing(2);
        width: 100%;
    }
}

.client__goal__figure__field {
    width: 52px;
    height: 24px;
    text-align: center;
    border: none;
    background: darken($ui-light, 2%);
    padding: 0;
}

.client__goal__figure__field__range {
    width: 100%;
}
