.website__blocks {
    > * {
        padding: spacing(16) spacing(4);
    }
}

// Hero Section
.feature__hero {
    height: 560px;
    width: 100%;
    padding: 0 spacing(4);
    background: #ecf1f8;
}

.feature__hero__inner {
    height: 100%;
    align-items: center;
    h1 {
        font-weight: 600;
    }
}

// Quote
.quote__feature {
    background: $primary;
}
.quote__feature__inner {
}
.quote__feature__text {
    h2 {
        color: #fff;
        font-size: 2.5rem;
        font-weight: 600;
    }
    p {
        color: #fff;
        font-weight: 500;
        font-size: 1.2rem;
    }
}
.quote__feature__quote {
    h4 {
        position: relative;
        color: #fff;
        font-size: 1.5rem;
        font-weight: 600;
        padding-top: spacing(4);
        margin-bottom: spacing(4);
        &:before {
            color: #7ceff3;
            content: open-quote;
            position: absolute;
            font-size: 100px;
            top: spacing(-4);
            left: spacing(-4);
            font-style: italic;
            height: 20px;
        }
        &:after {
            color: #7ceff3;
            content: close-quote;
            position: absolute;
            font-size: 100px;
            bottom: 0;
            right: 0;
            font-style: italic;
            height: 20px;
        }
    }
}

.quote__author {
    font-size: 1rem;
    color: #fff;
    margin: 0;
}
.quote__position {
    font-size: 1rem;
    color: #fff;
    margin: 0;
}
// CTA Section
.cta__feature {
    &.bg {
        background: #051133;
    }
}
.cta__feature__inner {
}

.cta__feature__text {
    h2 {
        font-size: 2.5rem;
        color: #fff;
        font-weight: 600;
    }
    p {
        color: #fff;
        font-weight: 500;
        font-size: 1.2rem;
    }
}

.cta__button {
    padding: spacing(1) spacing(8);
    margin-top: spacing(2);
    display: inline-block;
    border-radius: 4px;
    background-color: #55eae4;
    color: #051133;
    transition: 0.2s;
    user-select: none;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    font-weight: 600;
    &:hover {
        color: #051133;
        background-color: mix(#ffffff, #55eae4, 40%);
    }
}

// Feature Grid
.feature__grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: $centcol;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
}

.feature__grid__item {
    width: 100%;
    max-width: 380px;
    padding: spacing(2);
    padding: spacing(8) spacing(4);
}

.feature__grid__icon {
    background-color: #7ceff3;
    margin-bottom: spacing(2);
    width: 64px;
    height: 64px;
    border-radius: 50%;
}
.cta__feature {
    .react-tabs__tab-list {
        margin: 0 auto spacing(10) auto;
        max-width: 1140px;
        display: flex;
        justify-content: space-around;
    }

    .react-tabs__tab {
        display: inline-block;
        color: #fff;
        cursor: pointer;
        user-select: none;
        &.react-tabs__tab--selected {
            border-bottom: solid 2px #55eae4;
        }
    }
}

.stack__logo {
    max-height: 28px;
}

// Footer
.website__footer {
    width: 100%;
    padding: 0 spacing(4);
}
.website__footer__inner {
    max-width: 1140px;
    margin: 0 auto;
    height: 88px;
    padding: spacing(1) 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
