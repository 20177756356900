@import 'color.scss';
@import './fonts.scss';
@import url('https://fonts.googleapis.com/css?family=Roboto:400,600,700&display=swap');

$font-family-text: 'HK Grotesk', Helvetica, Arial, sans-serif !default;

$input-default-height: 48px !default;
$color-blue: $primary;

@import '../../node_modules/@threadws/reset/src/kube/kube.scss';
/* slider scss used in TeamTimeRow.jsx - when rebuilding remove dependancy */
@import '../../node_modules/@threadws/reset/src/kube/addons/slider.scss';
@import '../../node_modules/react-datepicker/dist/react-datepicker.css';
@import '../../node_modules/react-input-range/lib/css/index.css';
@import '../../node_modules/slick-carousel/slick/slick.css';
@import '../../node_modules/slick-carousel/slick/slick-theme.css';

$font-family-header: 'HK Grotesk', Helvetica, Arial, sans-serif;
html,
body,
#app {
  height: 100%;
}

.no-sroll {
  overflow: hidden;
}

:root {
  --bp-xs-width: 360px;
  --bp-sm-width: 768px;
  --bp-md-width: 992px;
  --bp-lg-width: 1200px;
  --spacing-value: 8px;
}

// SCSS version of above for media queries
$bp-xs-width: 360px;
$bp-sm-width: 768px;
$bp-md-width: 992px;
$bp-lg-width: 1200px;

// Used for global easing when adding transitions
$easing: 0.4s;
// Central column
$centcol: 1140px;

@function spacing($val) {
  @return (8 * $val) + px;
}

@import 'grid.scss';

/* @import './pages/Login.scss'; */
/* @import './pages/PasswordReset.scss'; */
/* @import './pages/SalesCycle.scss'; */
@import './pages/SalesCalculator.scss';
@import './pages/Payments.scss';

@import './components/ProfileDropdown.scss';

// @import './components/Stacks.scss';
@import './components/Hero.scss';
@import './components/Loader.scss';
@import './components/Success.scss';
@import './components/Meta.scss';
@import './components/Breadcrumb.scss';
@import './components/AddStepControl.scss';
// @import './components/Button.scss';
@import './components/CollateralControl.scss';
@import './components/IntroBreadcrumb.scss';
@import './components/MultiOptionControl.scss';
@import './components/SelectableCard.scss';
@import './components/CompleteCard.scss';
@import './components/ChevronLink.scss';
@import './components/FormTooltip.scss';
@import './components/Tab.scss';
@import './components/StageModal.scss';

// Website
@import './components/Website/HomePage.scss';
@import './components/Website/OverlapImage.scss';
@import './components/Website/FeatureList.scss';
@import './components/Website/Feature3ColList.scss';
@import './components/Website/Pricing.scss';
@import './components/Website/MediaFeature.scss';
@import './components/Website/GridFigure.scss';
@import './components/Website/Nav.scss';
@import './components/Website/MegaFigure.scss';

// Business Goals
@import './components/BusinessGoals/BusinessGoals.scss';
@import './components/BusinessGoals/BusinessGoalsSteps.scss';
@import './components/BusinessGoals/BusinessGoalsProgress.scss';
@import './components/BusinessGoals/BusinessGoalsCard.scss';
@import './components/BusinessGoals/BusinessGoalsLinkList.scss';

// Revenue Goals
@import './components/RevenueGoals/RevenueGoals.scss';

// Client Goals
@import './components/ClientGoals/ClientGoals.scss';

// Team Time
@import './components/TeamTime/TeamTimeAllocation.scss';
@import './components/TeamTime/TeamTimePercentageGraph.scss';

// Create Account
@import './components/CreateAccount/CreateAccount.scss';
@import './components/CreateAccount/CreateAccountForm.scss';

// Header
@import './components/Header/Header.scss';
@import './components/Header/CurrencySwitcher.scss';

h1,
h2,
h3,
h4,
h5 {
  font-family: $font-family-header;
}

// Going to refactor
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #051133;
}

.app__body {
  margin-top: spacing(4);
}

.mw__container {
  margin: 0 auto;
  max-width: 1140px;
  width: 100%;
}

.mw__fluid {
  padding: 0 spacing(4);
  width: 100%;
}

.input__row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}

.version {
  position: fixed;
  font-size: 0.9rem;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.2);
  font-family: $font-family-header;
  bottom: 5px;
  right: 5px;
  &:before {
    content: 'v';
  }
}
