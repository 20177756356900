.breadcrumb {
    height: 80px;
    display: flex;
    width: 100%;
    background-color: #fff;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    padding-right: spacing(4);
    h2 {
        margin-left: spacing(4);
        cursor: pointer;
    }
}
