.media__feature {
}
.media__feature__inner {
}
.media__feature__text {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    h1 {
        width: 100%;
        font-size: 3rem;
        font-weight: 600;
    }
    h2 {
        width: 100%;
        font-size: 2.5rem;
        font-weight: 600;
    }
    p {
        width: 100%;
        color: $text-light;
        font-weight: 500;
        font-size: 1.2rem;
    }
}

.mail__link {
    color: $primary;
    transition: 0.2s;
    h2,
    h3 {
        color: $primary;
        transition: 0.2s;
    }
    &:hover {
        color: lighten($primary, 10%);
        h2,
        h3 {
            color: lighten($primary, 10%);
        }
    }
}
