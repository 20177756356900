@for $i from 0 to 5 {
    .loader__path__#{$i} {
        animation: loader 2s infinite;
        animation-direction: alternate;
        animation-delay: #{'-0.' + ($i * 2) + 's'};
    }
}

@keyframes loader {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
