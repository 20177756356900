.register__grid {
    display: flex;
    justify-content: center;
    margin: 0 16px;
    h2 {
        text-align: center;
    }
}

.register,
.create__company,
.profile {
    width: 400px;
    display: none;
    &.step--active {
        display: block;
    }
}
