.stage__modal__overlay {
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    top: 0px;
    z-index: 5;
    background: rgba(0, 0, 0, 0.5);
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.stage__modal {
    max-width: 800px;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    max-height: calc(100vh - 64px);
    display: grid;
    grid-template-rows: 64px 1fr;
}

.stage__modal__form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: spacing(4);
    overflow: auto;
    label {
        font-size: 1rem;
        font-weight: bold;
        font-family: $font-family-header;
    }
}

.step__modal__form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: spacing(4);
    overflow: auto;
    padding: spacing(4);
    label {
        font-size: 1rem;
        font-weight: bold;
        font-family: $font-family-header;
    }
}

.step__modal__multi {
    display: grid;
    grid-gap: spacing(4);
    grid-template-columns: repeat(3, 1fr);
    padding: spacing(4);
}
