.business__goals__steps {
    grid-area: steps;
    padding: spacing(2);
    background: $ui-light;
    border-radius: 8px;
}

.business__goals__step {
    font-weight: 600;
    width: 100%;
    padding: 8px 0;
    border-bottom: solid 1px $border;
    position: relative;
    &:before {
        content: '';
        width: 20px;
        height: 20px;
        background: $border;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        border-radius: 50%;
    }
    &:after {
        content: '';
        transform: translate(0, -1px) rotate(45deg);
        height: 8px;
        width: 5px;
        border-bottom: 2px solid #fff;
        border-right: 2px solid #fff;
        position: absolute;
        right: 8px;
        top: 0;
        bottom: 0;
        margin: auto;
    }
    &.is-active {
        &:before {
            background: $primary;
        }
    }
}
