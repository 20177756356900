.sub__tabs {
    display: flex;
    border-bottom: 2px solid $border;
    margin: 0 spacing(4);
}

.sub__tab {
    border-bottom: 5px solid transparent;
    padding: spacing(3);
    font-size: 1.2rem;
    color: #000;
    font-weight: 600;
    text-decoration: none;
    &:hover {
        color: #000;
    }
    &.active {
        border-bottom: 5px solid $border;
    }
}

.dummy-data__text{
    border-radius: 4px;
    padding: 8px;
    background: #CBDDFE;
    display: inline-block;
    margin: 0 auto;
    font-size: 20px;
    font-weight: 600;
    color: #4d4dff;
}
.ninja { display: none; }